/* You can add global styles to this file, and also import other style files */
@import 'dockview-core/dist/styles/dockview.css';

/* MSHIP Design changes */
@layer mship-app {
  html body {
    overflow: hidden;
  }

  html body main {
    padding: 0;
    margin: 0;
    display: flex;
    overflow: hidden;
  }

  .mship-app-shell-notification {
    display: none;
  }
}

/* dockview overrides */
.dockview-theme-light {
  --dv-background-color: transparent;
  --dv-paneview-active-outline-color: dodgerblue;
  --dv-tabs-and-actions-container-font-size: 13px;
  --dv-tabs-and-actions-container-height: 35px;
  --dv-drag-over-background-color: rgb(83 89 93 / 50%);
  --dv-drag-over-border-color: white;
  --dv-tabs-container-scrollbar-color: #888;
  --dv-icon-hover-background-color: rgb(90 93 94 / 31%);
  --dv-floating-box-shadow: 8px 8px 8px 0px rgb(83 89 93 / 50%);
  --dv-group-view-background-color: white;

  --dv-tabs-and-actions-container-background-color: var(--petrol-100);

  --dv-activegroup-visiblepanel-tab-background-color: var(--petrol-400);
  --dv-activegroup-hiddenpanel-tab-background-color: var(--petrol-600);
  --dv-activegroup-visiblepanel-tab-color: white;
  --dv-activegroup-hiddenpanel-tab-color: var(--grau-200);

  --dv-inactivegroup-visiblepanel-tab-background-color: var(--petrol-400);
  --dv-inactivegroup-hiddenpanel-tab-background-color: var(--petrol-600);
  --dv-inactivegroup-visiblepanel-tab-color: white;
  --dv-inactivegroup-hiddenpanel-tab-color: var(--grau-200);

  --dv-tab-divider-color: transparent;

  --dv-separator-border: rgb(128 128 128 / 35%);
  --dv-paneview-header-border-color: rgb(51 51 51);
}

.dockview-theme-dark {
  --dv-background-color: transparent;
  --dv-paneview-active-outline-color: dodgerblue;
  --dv-tabs-and-actions-container-font-size: 13px;
  --dv-tabs-and-actions-container-height: 35px;
  --dv-drag-over-background-color: rgb(83 89 93 / 50%);
  --dv-drag-over-border-color: white;
  --dv-tabs-container-scrollbar-color: #888;
  --dv-icon-hover-background-color: rgb(90 93 94 / 31%);
  --dv-floating-box-shadow: 8px 8px 8px 0px rgb(83 89 93 / 50%);
  --dv-group-view-background-color: #1e1e1e;

  --dv-tabs-and-actions-container-background-color: var(--petrol-800);

  --dv-activegroup-visiblepanel-tab-background-color: var(--petrol-400);
  --dv-activegroup-hiddenpanel-tab-background-color: var(--petrol-600);
  --dv-activegroup-visiblepanel-tab-color: white;
  --dv-activegroup-hiddenpanel-tab-color: var(--grau-200);

  --dv-inactivegroup-visiblepanel-tab-background-color: var(--petrol-400);
  --dv-inactivegroup-hiddenpanel-tab-background-color: var(--petrol-600);
  --dv-inactivegroup-visiblepanel-tab-color: white;
  --dv-inactivegroup-hiddenpanel-tab-color: var(--grau-200);

  --dv-tab-divider-color: transparent;

  --dv-separator-border: rgb(68 68 68);
  --dv-paneview-header-border-color: rgb(204 204 204 / 20%);
}

.dv-dockview {
  background-color: transparent;
}

#dlp .sash-container > .sash {
  background-color: var(--lime-300);
}

#dlp .mship-dark-theme .sash-container > .sash {
  background-color: var(--lime-300);
}

.dv-render-overlay iframe {
  border: 0;
}

.tabs-and-actions-container {
  align-items: flex-end;
  padding-left: 0.6rem;
  border-bottom: 2px solid var(--petrol-500);

  .tabs-container {
    max-height: 80%;
    min-height: 80%;

    .tab {
      border-top-left-radius: var(--mship-layout-border-radius);
      border-top-right-radius: var(--mship-layout-border-radius);
      margin-left: 0.2rem;

      .dv-default-tab {
        border-top-left-radius: var(--mship-layout-border-radius);
        border-top-right-radius: var(--mship-layout-border-radius);
        padding-top: 0.3rem;

        .dv-default-tab-action {
          margin-top: -0.15rem;
        }
      }

      &.active-tab {
        .dv-default-tab {
          background-color: var(--petrol-500);
        }

        .mship-theme-dark & .dv-default-tab {
          background-color: var(--petrol-400);
        }
      }

      &.inactive-tab {
        .dv-default-tab {
          background-color: var(--petrol-600);
        }

        .mship-theme-dark & .dv-default-tab {
          background-color: var(--petrol-600);
        }
      }
    }
  }
}

.message-no-margin {
  .p-message {
    margin: 0;
  }
}
